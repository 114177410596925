import React, { useEffect } from 'react';
import * as styles from './layout.module.scss';
import TagManager from "react-gtm-module"
import Header, { enableGoogleTagManagerBody } from "./header/header";
import Footer from "./footer/footer";
import {ChatbotFieldsFragment, FooterFieldsFragment, StickyButtonsFieldsFragment} from "../../../graphql-types";
import StickyButtons from "../sticky-buttons/sticky-buttons";
import { Helmet } from 'react-helmet';
import { config } from 'process';

type RenderProps = {
    data: FooterFieldsFragment,
    stickyButtonsData: StickyButtonsFieldsFragment,
    chatbotData: ChatbotFieldsFragment,
    seo?: any,
    pageTemplate: String,
}

const Layout:React.FC<RenderProps> = ({data, stickyButtonsData, chatbotData, children, seo, pageTemplate}) => {

    useEffect(() => {
          enableGoogleTagManagerBody()
          TagManager.initialize({ gtmId: 'GTM-PN68QWGV' })
          TagManager.dataLayer({
            dataLayer: { environment: process.env.NODE_ENV, language: 'fr', pageTemplate },
          })
        
      }, [])

    return (
        <div className={styles.container}>
            <Helmet htmlAttributes={{ lang: 'fr' }}>
                <meta charSet="utf-8" />
                <title>{seo ? seo.title : "Voyages Keolis"}</title>
                <meta name="description" content={seo ? seo.description : ""} />
            </Helmet>
            <Header />
            <main id="main">
                {children}
            </main>
            <StickyButtons data={stickyButtonsData} chatbotData={chatbotData} />
            <Footer data={data} />
        </div>
    )

}

export default Layout;